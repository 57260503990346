import React from "react";

function ErrorText(props) {
  const style = {
    color: "red",
    margin: "0 auto",
    paddingTop: "10px",
  };
  return <p style={style}>{props.text}</p>;
}

function Error(props) {
  if (props.errorText) {
    return <ErrorText text={props.errorText} />;
  }
  return null;
}

export default Error;
