import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <>
      <div className="parent">
        <h3 className="emoji">😍</h3>
        <h3 className="included">QR Code Menu Templates Include</h3>
        <ul>
          <li>2x2 Sticker Displays</li>
          <li>3x5 Table Top Display</li>
          <li>4x6 Table Top Display</li>
          <li>5x7 Table Top Display</li>
          <li>8.5x11 Window Display</li>
        </ul>
      </div>
      <h4 className="link-text">
        Looking for a more robust solution?{" "}
        <a
          href="https://barpay.com/digital-menus"
          rel="noreferrer"
          target="_blank"
        >
          <span>Try dynamic QR code menus here.</span>
        </a>
      </h4>
      <a
        href="https://barpay.com/privacy-policy"
        rel="noreferrer"
        target="_blank"
      >
        <h5 className="link-text">Privacy Policy</h5>
      </a>
    </>
  );
}

export default Footer;
