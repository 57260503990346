import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import EmailIcon from "@material-ui/icons/Email";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ReCAPTCHA from "react-google-recaptcha";
import Error from "../errors/Errors";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © Barpay, LLC "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const GreenCheckbox = withStyles({
  root: {
    color: "#323e48",
    "&$checked": {
      color: "#323e48",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function Form(props) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: "#323e48",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(2),
      border: "solid",
      borderColor: "#323e48",
      borderWidth: "1.5px",
      padding: "30px",
      backgroundColor: "white",
    },
    submit: {
      height: "50px",
      margin: theme.spacing(3, 0, 2),
      backgroundColor: "#323e48",
      "&:hover": {
        backgroundColor: "#414f5a",
        color: "#FFF",
      },
      "&:disabled": {
        backgroundColor: props.submitted ? "#323e48" : "#828282",
        color: "#FFF",
      },
    },
    agreeLabel: {
      fontSize: "10px",
      marginBottom: "15px",
    },
    formText: {
      fontSize: "14px",
      fontWeight: "bold",
      padding: "10px",
    },
    // Was able to find a solution to changing the Material UI componet properties here:
    //https://stackoverflow.com/questions/58963242/change-border-color-on-material-ui-textfield
    root: {
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#323e48",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#323e48",
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "#323e48",
      },
    },
  }));
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <EmailIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Generate Your Templates
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                className={classes.formText}
                component="h5"
                variant="h5"
              >
                Step 1 - Upload your logo
              </Typography>
              <Input
                id="fileUpload"
                type="file"
                disableUnderline={true}
                onChange={(event) => {
                  props.onFileChange(event);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.formText}
                component="h5"
                variant="h5"
              >
                Step 2 - Enter your QR code menu link
              </Typography>
              <TextField
                className={classes.root}
                size="small"
                name="url"
                variant="outlined"
                required
                fullWidth
                id="urlInput"
                label="Link"
                placeholder="ie https://mywebsite.com/view-menu"
                error={props.error.link}
                helperText={props.error.link ? props.error.linkHelperText : ""}
                onChange={(e) => props.onLinkChange(e.target.value)}
                value={props.link}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.formText}
                component="h5"
                variant="h5"
              >
                Step 3 - Enter your email
              </Typography>
              <TextField
                className={classes.root}
                size="small"
                variant="outlined"
                required
                fullWidth
                id="emailInput"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={props.error.email}
                helperText={
                  props.error.email ? props.error.emailHelperText : ""
                }
                onChange={(e) => props.onEmailChange(e.target.value)}
                value={props.email}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    onChange={(e) => props.onCheckboxChange(e.target.checked)}
                    checked={props.checked}
                  />
                }
                label={
                  <Typography
                    className={classes.agreeLabel}
                    component="p"
                    variant="inherit"
                  >
                    {"I agree to the "}{" "}
                    <Link
                      color="primary"
                      href="https://barpay.com/terms-of-service"
                      target="_blank"
                    >
                      Terms of Service
                    </Link>
                  </Typography>
                }
              />
              <ReCAPTCHA
                sitekey="6Lf3jUcaAAAAALZL0QG2RG2SNLD_0hUCkNc7ECF9"
                onChange={(tok) => props.onRecapChange(tok)}
                onExpired={props.onRecapExpired}
                onErrored={props.onRecapErrored}
              />
            </Grid>
          </Grid>
          <Error errorText={props.errorText} />
          <Button
            disabled={props.buttonState.isDisabled}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={props.submitForm}
          >
            {props.buttonState.label}
          </Button>
          <Copyright />
        </form>
      </div>
    </Container>
  );
}
