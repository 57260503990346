import React from "react";
import "./Header.css";

function Header() {
  return (
    <div className="header">
      <a href="https://www.barpay.com" rel="noreferrer" target="_blank">
        <img
          src="/Logo-Dark.png"
          alt="barpay qr code menu logo"
          className="img"
        />
      </a>
    </div>
  );
}

export default Header;
