import "./App.css";
import { Header, Form, Preview } from "./components";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import React, { useState } from "react";
import Title from "./components/title/Title";
import Footer from "./components/footer/Footer";
const isUrl = require("is-url-superb");
var validator = require("email-validator");
const axios = require("axios");
var token;
var fileEvent;
var isCorrectImgSize = false;

function App() {
  const [previewImg, setPreviewImgState] = useState("/suffolk-punch-logo.png");

  const [buttonState, setButtonState] = useState({
    isDisabled: true,
    label: "Get My QR Code Menu Templates",
  });
  const [link, setLink] = useState("");
  const [email, setEmail] = useState("");
  const [checkBox, setCheckbox] = useState(false);
  const [ready, setReady] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [uploadFile, setUploadFile] = useState(false);
  const [qrImage, setQrImage] = useState(
    "https://thesuffolkpunch.com/wp-content/uploads/2020/12/Main-12.28.pdf"
  );
  const [error, setError] = useState({
    link: false,
    email: false,
    emailHelperText: "Please enter a valid email",
    linkHelperText: "Please enter a valid URL (include https://)",
  });

  function setBtnState(state, label) {
    var newState = { ...buttonState };
    newState.isDisabled = state;
    newState.label = label;
    setButtonState(newState);
  }

  const handleTOSBox = (isChecked) => {
    setCheckbox(isChecked);
    if (token && email && link && isChecked && uploadFile) {
      setBtnState(false, "Get My QR Code Menu Templates");
    }
  };

  const handleFileChange = () => {
    var reader = new FileReader();
    //Read the contents of Image File.
    reader.readAsDataURL(fileEvent.target.files[0]);
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (height < 200 || width < 200) {
          setErrorText("Minimum image height and width is 200px.");
          return;
        }
        setErrorText("");
        isCorrectImgSize = true;
        if (fileEvent.target.files.length > 0) {
          setUploadFile(true);
          setPreviewImgState(URL.createObjectURL(fileEvent.target.files[0]));
          if (token && email && link && checkBox && true) {
            setBtnState(false, "Get My QR Code Menu Templates");
          }
        }
      };
    };
  };

  const handleLinkChange = (link) => {
    setLink(link);
    setQrImage(link);
    var err = { ...error };
    if (link.length > 0) {
      let isValid = isUrl(link);
      err.link = isValid ? false : true;
      setError(err);
      if (token && email && link && checkBox && uploadFile) {
        setBtnState(false, "Get My QR Code Menu Templates");
      }
      return;
    }
    err.link = false;
    setError(error);
  };

  const handleEmailChange = (email) => {
    setEmail(email);
    var err = { ...error };
    if (email.length > 0) {
      let isValid = validator.validate(email);
      err.email = isValid ? false : true;
      setError(err);
      if (token && email && link && checkBox && uploadFile) {
        setBtnState(false, "Get My QR Code Menu Templates");
      }
      return;
    }
    err.email = false;
    setError(error);
  };

  const onRecapChange = (tok) => {
    token = tok;
    if (token && email && link && checkBox && uploadFile) {
      setBtnState(false, "Get My QR Code Menu Templates");
    }
  };

  const onRecapErrored = () => {
    setBtnState(true, "Get My QR Code Menu Templates");
  };

  function $w(id) {
    return document.getElementById(id);
  }

  function submitForm() {
    if (!isCorrectImgSize) {
      setErrorText(
        "Please fix the image size (Minimum 200px height and width)."
      );
      setBtnState(false, "Get My QR Code Menu Templates");
      return;
    }
    let size = fileEvent.target.files[0].size;
    if (size > 5000000) {
      setErrorText("Exceeded maximum logo size (5MB Limit).");
      setBtnState(false, "Get My QR Code Menu Templates");
      return;
    }
    if (!uploadFile) {
      setErrorText("Please upload a logo!");
      setBtnState(false, "Get My QR Code Menu Templates");
      return;
    }
    setBtnState(true, "Doing some magic!");
    setErrorText("");
    setReady(true);
    let email = $w("emailInput").value;
    let url = $w("urlInput").value;
    let ext = fileEvent.target.files[0].type;
    if (!ext || email.length === 0 || url.length === 0) {
      setErrorText("Please fill out all of the fields!");
      setBtnState(false, "Get My QR Code Menu Templates");
      return;
    }
    if (!(ext.includes("/png") || ext.includes("/jpeg"))) {
      setErrorText("The logo file type must be a png or jpeg");
      setBtnState(false, "Get My QR Code Menu Templates");
      return;
    }
    if (!checkBox) {
      setErrorText("You must agree to the terms of service!");
      setBtnState(false, "Get My QR Code Menu Templates");
      return;
    }
    let file = fileEvent.target.files[0];
    let data = {
      "g-recaptcha-response": token,
      url: formatEntry(url),
      email: formatEntry(email),
    };
    postData(data, file);
  }

  function formatEntry(string) {
    if (string) return string.toLowerCase().trim();
  }

  function postData(data, file) {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios.post("https://free.barpay.com/create", data, config).then((res) => {
      let config = {
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      };
      if (res.status === 200) {
        axios
          .put(res.data.uploadUrl, file, config)
          .then((res) => {
            if (res.status === 200) {
              setBtnState(true, "Success! Check your inbox!");
              return;
            }
          })
          .catch((err) => {
            console.log(err);
            return;
          });
        return;
      }
      setErrorText(
        "Uhh ohh something went wrong while submitting your information. Please try again. If the problem persists, please contact support@barpay.com"
      );
      setBtnState(false, "Get My QR Code Menu Templates");
    });
  }

  return (
    <>
      <Header />;
      <Title />
      <Container component="main" maxWidth="md">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Form
              onFileChange={(e) => {
                fileEvent = e;
                handleFileChange();
              }}
              onLinkChange={(link) => handleLinkChange(link)}
              onEmailChange={(email) => handleEmailChange(email)}
              link={link}
              email={email}
              error={error}
              buttonState={buttonState}
              onRecapChange={(tok) => onRecapChange(tok)}
              onRecapExpired={() => onRecapErrored()}
              onRecapErrored={() => onRecapErrored()}
              submitForm={() => submitForm()}
              onCheckboxChange={(isChecked) => handleTOSBox(isChecked)}
              checked={checkBox}
              errorText={errorText}
              ready={ready}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Preview previewImg={previewImg} qrImage={qrImage} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default App;
