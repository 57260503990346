import React from "react";
import "./Title.css";

function Title() {
  return (
    <div className="section">
      <h1 className="title">
        Generate <span className="coloredText">100% Free</span> QR Code Menu
        Templates
      </h1>
      <h4 className="desc">
        A free service brought to you by Barpay, the #1 provider of QR code
        menus in America. Convert any website link into print ready QR code menu
        templates in seconds! No account or setup required.
      </h4>
    </div>
  );
}

export default Title;
