import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { QRCode } from "react-qr-svg";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#323e48",
  },
  template: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "solid",
    borderColor: "#323e48",
    borderWidth: "1.5px",
    backgroundColor: "white",
  },
  img: {
    maxHeight: "200px",
    maxWidth: "325px",
    margin: "20px",
    marginBottom: "10px",
    padding: "5px",
  },
  qr: {
    width: "150px",
    margin: "15px",
  },
  text: {
    width: "80%",
    textAlign: "center",
    letterSpacing: "1.5px",
    margin: "0",
  },
  footerIcon: {
    width: "150px",
    paddingTop: "0px",
    margin: "5px",
  },
  poweredBy: {
    width: "150px",
    paddingTop: "0px",
    marginBottom: "20px",
  },
}));

export default function Preview(props) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <VisibilityIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sneak Peek
        </Typography>
        <div className={classes.template}>
          <img
            className={classes.img}
            src={props.previewImg}
            alt="Venue Logo QR Code Menu"
            id="previewImg"
          />
          <h1 className={classes.text}>CONTACTLESS MENU</h1>
          <QRCode className={classes.qr} level="L" value={props.qrImage} />
          <h4 className={classes.text}>
            SCAN QR CODE WITH PHONE CAMERA THEN CLICK POPUP LINK
          </h4>
          <img
            className={classes.footerIcon}
            src="/icons.png"
            alt="Contactless and Safe Icons For QR Code Menu"
          />
          <img
            className={classes.poweredBy}
            src="/powered-by-Barpay.png"
            alt="Barpay Logo For QR Code Menu"
          />
        </div>
      </div>
    </Container>
  );
}
